import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.css";
import { useDispatch } from "react-redux";
import { format, addDays, subDays } from "date-fns";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
// import { FaBell } from "react-icons/fa";
import Textarea from "components/Textarea";
// import styles from "features/members/styles.module.scss";
import { FaCalendarPlus } from "react-icons/fa6";
import { Accordion } from "react-bootstrap";
import Modal from "components/ModalWindow";
import useModal from "hooks/useModal";
import AddLeaveDaysForm from "./AddLeaveDaysForm";
import AddSpecialDaysForm from "./AddSpecialDaysForm";
import { getCalendar } from "API/calendar";
import { setError } from "redux/workspaceSlice";
import { formatDate } from "services/helpers";
import { FaEdit /*, FaTrash*/ } from "react-icons/fa";

export default function Agenda() {
  // const { name } = useSelector((state) => state.appsetting.account);
  // const display_workspace = useSelector((state) => state.workspaces.display_workspace);
  const dispatch = useDispatch();

  const [isAddLeavesDaysModal, openAddLeavesDaysModal, closeLeavesDaysModal] = useModal();
  const [isAddSpecialDaysModal, openAddSpecaialDaysModal, closeSpecialDaysModal] = useModal();
  const [date, setDate] = useState(new Date());
  const [text, setText] = useState("");
  const [leavesDays, setLeavesDays] = useState([]);
  const [leaveDayItem, setLeaveDayItem] = useState();

  const textRef = useRef(null);

  useEffect(() => {
    getCalendarData();
  }, []);

  const getCalendarData = async () => {
    // const workspaceId = workspace_id ? workspace_id : display_workspace;
    await getCalendar()
      .then((res) => {
        console.log(res, "res-calendar");
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error
            })
          );
          return;
        }
        setLeavesDays(res.data.result.leaves);
      })
      .catch((err) => {
        console.log(err, "err-add-leave-days");
        err?.response?.status === 401
          ? (window.location.href = "https://account2.task925.com/?killsession")
          : dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.message
              })
            );
      });
  };
  const showNextDate = () => {
    const nextDate = addDays(date, 1);
    setDate(nextDate);
  };

  const showPreviousDate = () => {
    const previousDate = subDays(date, 1);
    setDate(previousDate);
  };

  console.log(leavesDays, "leavesdays");
  return (
    <>
      <div className="tasks-card card" style={{ minWidth: "300px" }}>
        <div className="project-card-header">
          <div className="d-flex justify-content-between tasks-card-title project-card-title">
            <div className="prev nav-item" onClick={showPreviousDate}>
              <BiLeftArrow />
            </div>
            <span>{format(date, "MMM dd, eee")}</span>
            <div className="next nav-item" onClick={showNextDate}>
              <BiRightArrow />
            </div>
          </div>
        </div>

        {/* <div className="agenda-card-body"> */}
        <div className="mt-2">
          <Textarea
            textareaRef={textRef}
            placeholder="Enter your text..."
            rows={4}
            value={text}
            onChange={(e) => setText(e.target.value)}
            // isDisable={isDisable}
          />
          <Accordion defaultActiveKey="0" className="my-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FaCalendarPlus
                  className="me-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddLeavesDaysModal(true);
                    setLeaveDayItem(null);
                  }}
                />
                Leave days
              </Accordion.Header>
              <Accordion.Body>
                {leavesDays.length > 0 &&
                  leavesDays.map((item) => (
                    <div key={item._id} className="d-flex w-100 justify-content-between mb-2">
                      <div>
                        <span>{formatDate(item.date_from)}</span> - <span>{formatDate(item.date_to)}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* <Form.Group className="me-2">
                          <label className="switch ms-2">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </Form.Group> */}
                        <FaEdit
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLeaveDayItem(item);
                            openAddLeavesDaysModal(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <FaCalendarPlus
                  className="me-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddSpecaialDaysModal(true);
                  }}
                />
                Special days
              </Accordion.Header>
              <Accordion.Body>Friday happy</Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <div className={styles.memberTasks}>
          <div className="project-card-body-wrapper">
            <FaBell />
            <span className="ms-2">{name} on leave</span>
          </div>
        </div>
        <div className={styles.memberTasks}>
          <div className="project-card-body-wrapper">
            <FaBell />
            <span className="ms-2">{name} birthay</span>
          </div>
        </div> */}
        </div>
      </div>
      <Modal
        isShow={isAddLeavesDaysModal}
        modalHeader={leaveDayItem ? "Edit Leave Days" : "Add Leave Days"}
        handleClose={() => {
          closeLeavesDaysModal();
          document.body.classList.remove("overflow-hidden");
          document.body.classList.add("overflow-auto");
        }}
        // size="xl"
        headerStyles={{ padding: "32px" }}
        bodyStyles={{ padding: "32px 32px 32px 32px", minHeight: "392px" }}
      >
        <AddLeaveDaysForm onClose={closeLeavesDaysModal} leaveDayItem={leaveDayItem} updateCalendarData={getCalendarData} />
      </Modal>
      <Modal
        isShow={isAddSpecialDaysModal}
        modalHeader="Add Special Days"
        handleClose={() => {
          closeSpecialDaysModal();
          document.body.classList.remove("overflow-hidden");
          document.body.classList.add("overflow-auto");
        }}
        // size="xl"
        headerStyles={{ padding: "24px" }}
        bodyStyles={{ padding: "24px", height: "500px" }}
      >
        <AddSpecialDaysForm onClose={closeSpecialDaysModal} />
      </Modal>
    </>
  );
}
