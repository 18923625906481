import React, { useState, useRef, useEffect } from "react";
import "./Dashboard.css";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Textarea from "components/Textarea";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { formatDateForServer } from "services/helpers";
import { addLeaveRequest, editLeaveRequest } from "API/calendar";
import { setError } from "redux/workspaceSlice";
import { toast } from "react-toastify";
import MultiSelect from "components/MultiSelect";

const AddLeaveDaysForm = ({ onClose, leaveDayItem = null, updateCalendarData }) => {
  // console.log(leaveDayItem, "leaveDayItem");
  registerLocale("en-US", enUS);
  const { accountISbb_accountsID } = useSelector((state) => state.appsetting.account);
  const role = accountISbb_accountsID === "246" ? "Admin" : "Actioner";

  const dispatch = useDispatch();

  const [admins, setAdmins] = useState(null);
  const [adminsOptions /*, setAdminsOptions*/] = useState([{ label: "Red", _id: "246" }]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //   const [rangeDate, setRangeDate] = useState([]);
  const [rangeDateError, setRangeDateError] = useState("");
  const [formData, setFormData] = useState({
    accountISbb_accountsID,
    admins: [],
    date_start: "",
    date_end: "",
    textISsmallplaintextbox: "",
    status: leaveDayItem === null ? "pending" : ""
  });

  const textRef = useRef(null);
  const statuses = ["pending", "approved", "rejected"];

  useEffect(() => {
    if (leaveDayItem) {
      setStartDate(leaveDayItem.date_from);
      setEndDate(leaveDayItem.date_to);
      setFormData({
        workspace_id: leaveDayItem.workspaceISbb_t925_workspacesID,
        date_start: leaveDayItem.date_from,
        date_end: leaveDayItem.date_to,
        textISsmallplaintextbox: leaveDayItem.textISsmallplaintextbox,
        status: leaveDayItem === null ? "pending" : leaveDayItem.status
      });
    }
  }, [leaveDayItem]);

  const onDateRangeChangeHandler = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    console.log(start, end, start && end === "1970-01-01", "start-end");
    if (start && (end === null || end === "1970-01-01")) {
      setRangeDateError("Please select the end date!");
    } else {
      setRangeDateError("");
      setFormData({
        ...formData,
        date_start: formatDateForServer(start),
        date_end: formatDateForServer(end)
      });
      //   setRangeDate([formatDateForServer(start), formatDateForServer(end)]);
      setEndDate(end);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "formData");
    const request = leaveDayItem ? editLeaveRequest({ ...formData, _id: leaveDayItem._id }) : addLeaveRequest(formData);
    await request
      .then((res) => {
        console.log(res, res.data, "get-docs");
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error
            })
          );
          return;
        }
        if (res.data.success === 1) {
          toast.success(res.data.message, {
            theme: "colored"
          });
          updateCalendarData();
        }
        onClose();
      })
      .catch((err) => {
        console.log(err, "err-add-leave-days");
        err?.response?.status === 401
          ? (window.location.href = "https://account2.task925.com/?killsession")
          : dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: err.message
              })
            );
      });
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="d-flex flex-column h-100 gap-4">
        <div className="d-flex flex-column gap-4 flex-grow-1">
          <div className="selectsBlock">
            {/* <Form.Group className="selectItem mb-3 me-1">
          <Form.Label>Workspaces</Form.Label>
          <Form.Select value={formData.workspace_id} onChange={(e) => setFormData({ ...formData, workspace_id: e.target.value })} required>
            <option value="" hidden>
              Workspace
            </option>
            <option value="">None</option>

            {workspaces?.map((workspace) => (
              <option value={workspace._id} key={workspace._id}>
                {workspace.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group> */}
            <Form.Group className="selectItem d-flex flex-column">
              <Form.Label>Admins *</Form.Label>
              <MultiSelect
                onChange={(data) => {
                  // console.log(data, "data");
                  setAdmins(data);
                  setFormData({ ...formData, admins: data.map(item => item.value) });
                }}
                options={adminsOptions}
                selected={admins}
                placeholder="Select admins"
                required
                style={{ height: "36px" }}
                // className={formData.admins.length === 0 ? "border-danger" : ""}
              />
            </Form.Group>

            <Form.Group className="selectItem d-flex flex-column">
              <Form.Label>Leave days dates *</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={onDateRangeChangeHandler}
                startDate={startDate}
                formatWeekDay={(day) => day.slice(0, 3)}
                endDate={endDate}
                selectsRange
                dateFormat="MMM dd"
                locale="en-US"
                placeholderText="Select Date"
                showYearDropdown
                customInput={<DatePickerCustomInput rangeDateError={rangeDateError} className="w-100" />}
              />
              <span className="mt-1">{rangeDateError}</span>
            </Form.Group>
          </div>
          <Form.Group className="tw-w-full">
            <Form.Label>Description *</Form.Label>
            <Textarea
              textareaRef={textRef}
              placeholder="Enter your text..."
              rows={4}
              value={formData.textISsmallplaintextbox}
              onChange={(e) => setFormData({ ...formData, textISsmallplaintextbox: e.target.value })}
            />
          </Form.Group>

          <Form.Group className="selectItem">
            <Form.Label>Statuses</Form.Label>
            <Form.Select
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              id={
                role !== "Admin" ? "selectId" : ""
                // ? "readOnlyPlaceholderId"
              }
              style={role !== "Admin" ? { backgroundColor: "transparent", color: "black" } : {}}
              disabled={role !== "Admin"}
              required
            >
              <option value="" hidden>
                Statuses
              </option>
              <option value="">None</option>

              {statuses.map((status) => (
                <option value={status} key={status}>
                  {status}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="d-flex justify-content-end mt-auto">
          <Button
            type="submit"
            disabled={formData.admins.length === 0 || !formData.date_start || !formData.date_end || !formData.textISsmallplaintextbox}
          >
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

AddLeaveDaysForm.propTypes = {
  onClose: PropTypes.func,
  leaveDayItem: PropTypes.object,
  updateCalendarData: PropTypes.func
};
export default AddLeaveDaysForm;
